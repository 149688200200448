import { getCurrencySymbol } from '@utils/currency';

export const ROLES = {
  ADMIN: 'andisor',
  BRAND: 'brand',
  RETAILER: 'retailer',
} as const;

export enum BrandOrderStatus {
  DRAFT = 'draft',
  PLACED = 'placed',
  ACCEPTED = 'accepted',
  SELLER_AMENDED = 'seller_amended',
  BUYER_AMENDED = 'buyer_amended',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  DISPUTED = 'disputed',
  RESOLVED = 'resolved',
  PROPOSED = 'proposed',
  REJECTED = 'rejected',
}

export enum BrandOrderPaymentStatus {
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export enum BrandOrderDeliveryStatus {
  PENDING = 'pending',
  IN_TRANSIT = 'in_transit',
  INVALID_TRACKING_ID = 'invalid_tracking_id',
  DELIVERED = 'delivered',
  NOT_DELIVERED = 'not_delivered',
}

export const DROPSHIP_REQUEST_STATUS = {
  awaiting_verification: {
    label: 'Awaiting Verification',
    value: 'awaiting_verification',
  },
  pending: {
    label: 'Pending',
    value: 'pending',
  },
  approved: {
    label: 'Approved',
    value: 'approved',
  },
  declined: {
    label: 'Declined',
    value: 'declined',
  },
  connected: {
    label: 'Connected',
    value: 'connected',
  },
} as const;

export const DROPSHIP_PROPOSAL_STATUS = {
  pending: {
    label: 'Pending',
    value: 'pending',
  },
  approved: {
    label: 'Approved',
    value: 'approved',
  },
  declined: {
    label: 'Declined',
    value: 'declined',
  },
};

export const PRIVATE_CATALOG_REQUEST_STATUS = {
  pending: {
    label: 'Pending',
    value: 'pending',
  },
  approved: {
    label: 'Approved',
    value: 'approved',
  },
  declined: {
    label: 'Declined',
    value: 'declined',
  },
};

export const MIN_STOCK_TO_DROP_SHIP = 5;

export const ANDISOR_LEGAL_LINKS = {
  BUYER_TERMS_AND_CONDITION:
    'https://andisor-assets.s3.ap-southeast-2.amazonaws.com/Buyer+terms+of+usage.docx.pdf',
  SELLER_TERMS_AND_CONDITION:
    'https://andisor-assets.s3.ap-southeast-2.amazonaws.com/Supplier+Terms+and+Conditions_Andisor_Mar22.docx.pdf',
  RETURN_AND_REFUND_POLICY:
    'https://andisor-assets.s3.ap-southeast-2.amazonaws.com/Returns+and+Refund+Policy.pdf',
  PRIVACY_POLICY: 'https://andisor-assets.s3.ap-southeast-2.amazonaws.com/Privacy+Policy.docx.pdf',
};

export const ANDISOR_BLOG_URL = 'https://andisor.com/news-hub';

export const ANDISOR_HUBSPOT_MEET_URL = 'https://meetings.hubspot.com/tom-macdonald1';

export const ENABLE_GST = process.env.NEXT_PUBLIC_APP_ENABLE_GST?.toLowerCase() === 'true';
export function getGSTPercentage(): number {
  let gstPercentage = 0;
  if (ENABLE_GST) {
    const gstPercentageParam = Number.parseFloat(process.env.NEXT_PUBLIC_APP_GST_PERCENTAGE ?? '');
    if (!Number.isNaN(gstPercentageParam)) {
      gstPercentage = gstPercentageParam;
    }
  }
  return gstPercentage;
}

export const GST_PERCENT = getGSTPercentage();

export const MAX_BANK_TRANSFER_TOTAL = 1500;

export const MIN_MAX_FILTERS_CONFIG = {
  moq: {
    step: 25,
    prefix: getCurrencySymbol(),
  },
  leadTime: {
    step: 1,
    suffix: 'w',
  },
  discount: {
    step: 1,
    suffix: '%',
  },
};

// 10 MB
export const MAX_IMAGE_UPLOAD_SIZE_BYTES = 10 * 1024 * 1024;
export const MAX_IMAGE_UPLOAD_SIZE_MB = 10;

export const FIRST_NATION_BRANDS_VALUE_ID = '7';

export const LOGO_PLACEHOLDER_URL = 'https://i.imgur.com/srVI4W9.png';

export const INDENT_VALUES = { ANY: 'ANY', TRUE: 'TRUE', FALSE: 'FALSE' };
export type IndentType = 'ANY' | 'TRUE' | 'FALSE';

export const PURCHASABLE_FILTER_VALUES = { ANY: 'ANY', TRUE: 'TRUE', FALSE: 'FALSE' };
export type IsPurchasableFilterType = 'ANY' | 'TRUE' | 'FALSE';

export const PURCHASABLE_VALUES = { ANY: 'ANY', TRUE: 'TRUE', FALSE: 'FALSE' };
export type PurchasableType = 'ANY' | 'TRUE' | 'FALSE';
