import {
  DatePicker as MantineDatePicker,
  DatePickerProps as MantineDatePickerProps,
  DatePickerStylesNames,
  DatePickerType,
} from '@mantine/dates';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type DatePickerClassNamesType = Partial<Record<DatePickerStylesNames, string>>;

const styles: ClassArrayOrString<DatePickerClassNamesType> = {
  day: '!h-7 disabled:text-andisor-gray-500 enabled:text-andisor-blue enabled:font-bold data-[selected=true]:text-white enabled:text-sm p-0',
  calendarHeader: '!m-0',
  calendarHeaderControl: '!h-8 text-xs',
  weekday: '!h-8 !text-xs',
  monthsListControl: '!text-xs',
  yearsListControl: '!text-xs',
  calendarHeaderLevel: '!h-8 !text-xs',
};

export interface DatePickerProps<Type extends DatePickerType = 'default'>
  extends MantineDatePickerProps<Type> {
  classNames?: DatePickerClassNamesType;
}

export const DatePicker = <Type extends DatePickerType = 'default'>(
  props: DatePickerProps<Type> & {
    ref?: React.ForwardedRef<HTMLDivElement>;
  }
) => {
  const { ref, classNames, ...restProps } = props;
  const mergedStyles = mergeMantineStyles(styles, classNames);

  return <MantineDatePicker ref={ref} classNames={mergedStyles} {...restProps} />;
};
