import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import cartSlice from './cart/cartSlice';
import commonSlice from './common/commonSlice';
import inventorySlice from './inventory/inventorySlice';
import personaSlice from './persona/personaSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['browse'],
};

const reducer = combineReducers({
  [personaSlice.name]: personaSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [inventorySlice.name]: inventorySlice.reducer,
  [commonSlice.name]: commonSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const clearPersistedState = async () => {
  await persistor.purge();
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
