import { useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { modals } from '@mantine/modals';
import { useErrorBoundary } from 'react-error-boundary';
import { Button, Heading, Text } from '@ui/core';
import HelpRequestModal from '@components/HelpRequestModal/HelpRequestModal';
import { ROUTES } from '@constants/route';
import styles from './styles.module.css';

const ErrorFallback = () => {
  const { resetBoundary } = useErrorBoundary();
  const router = useRouter();

  useEffect(() => {
    const resetPage = () => {
      resetBoundary();
    };
    router.events.on('routeChangeComplete', resetPage);
    return () => {
      router.events.off('routeChangeComplete', resetPage);
    };
  }, [resetBoundary, router.events]);

  return (
    <main>
      <Image
        alt="Glass"
        src="/assets/images/static/glass-bg.jpg"
        placeholder="empty"
        quality={100}
        fill
        sizes="100vw"
        className="object-cover md:object-[5%,0%]"
      />
      <section className={styles.container}>
        <div className={styles.content}>
          <Heading className={styles.textHeading}>uh-oh...</Heading>
          <section className={styles.contentBox}>
            <Text className={styles.textDescription}>
              You either cleverly found an error, can&apos;t type, or we broke something. Either
              way, tell us how this happened, and if you&apos;re willing to chat, we&apos;ll give
              you $50 credit for your time.
            </Text>
            <Button
              leftArrow
              className={styles.cta}
              onClick={() => {
                modals.open({
                  title: 'Support Request',
                  children: <HelpRequestModal closeModal={() => modals.closeAll()} />,
                });
              }}
            >
              Drop us a line
            </Button>
            <Text className={styles.textCaption}>
              Or, head back to our{' '}
              <a className={styles.textUnderline} href={ROUTES.COMMON.HOME}>
                homepage
              </a>
              .
            </Text>
          </section>
        </div>
      </section>
    </main>
  );
};

export default ErrorFallback;
