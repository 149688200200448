export const ROUTES = {
  SELLERS: {
    BASE_PATH: '/sellers',
    ORDERS: '/sellers/orders',
    ORDER_PROPOSALS_DRAFTS: '/sellers/orders/draft-proposals',
    SETTINGS: '/sellers/settings',
    SETTINGS_INVENTORY: '/sellers/settings/inventory',
    SETTINGS_INTEGRATION: '/sellers/settings/integration',
    SETTINGS_TEAM: '/sellers/settings/team',
    PERSONAS: '/sellers/persona',
    CUSTOMERS: '/sellers/customers',
    CUSTOMERS_REQUESTS: '/sellers/customers/requests',
    CUSTOMERS_CREATE_PROPOSAL: '/sellers/customers/create-proposal',
    PERSONA_TEMPLATE: '/sellers/persona/template',
    INVENTORY_BASE: '/sellers/inventory-selection',
    INVENTORY: '/sellers/inventory-selection/inventory',
    INVENTORY_DRAFT: '/sellers/inventory-selection/inventory-draft',
    INVENTORY_ARCHIVED: '/sellers/inventory-selection/inventory-archived',
    COLLECTIONS: '/sellers/inventory-selection/collections',
    PRICE_LIST: '/sellers/inventory-selection/price-list',
    NEW_COLLECTION: '/sellers/inventory-selection/collections/new',
    EDIT_COLLECTION: '/sellers/inventory-selection/collections/edit',
    DUPLICATE_COLLECTION: '/sellers/inventory-selection/collections/duplicate',
    ANALYTICS: '/sellers/inventory-selection/analytics',
    ONBOARDING_INPUT_INFORMATION: '/sellers/onboarding',
    SELLER_ANALYTICS: '/sellers/analytics',
    BRAND: '/sellers/brands',
    WALLET: '/sellers/wallet',
    DROPSHIP_BASE_PATH: '/sellers/dropship',
    DROPSHIP_REQUESTS: '/sellers/dropship/requests',
    DROPSHIP_PROPOSALS: '/sellers/dropship/proposals',
    MARKETING_HUB: '/sellers/marketing-hub',
    NEW_BALANCE_BRAND_PAGE: '/brands/new-balance',
    SHOPIFY_INSTALL_PAGE: '/sellers/shopify-install',
    PRODUCTS_SEARCH_PAGE: (brandId: number) => `/sellers/brands/${brandId}/search`,
  },

  BUYERS: {
    BASE_PATH: '/buyers',
    ANALYTICS: '/buyers/analytics',
    BROWSE: '/buyers/browse',
    PRODUCTS: '/buyers/product',
    COLLECTIONS: '/buyers/collections',
    SETTINGS: '/buyers/settings',
    ORDERS: '/buyers/orders',
    ORDER_PRODUCTS: '/buyers/orders/products',
    CART_CHECKOUT: '/buyers/cart/checkout',
    CART_DELIVERY: '/buyers/cart/delivery',
    CART_PAYMENT: '/buyers/cart/payment',
    CART_SUMMARY: '/buyers/cart/summary',
    DROPSHIP_CART_CHECKOUT: '/buyers/dropship-cart/checkout',
    DROPSHIP_CART_SUMMARY: '/buyers/dropship-cart/summary',
    DROPSHIP: '/buyers/dropship',
    DROPSHIP_REQUESTS: '/buyers/dropship/requests',
    DROPSHIP_PROPOSALS: '/buyers/dropship/proposals',
    ONBOARDING_INPUT_INFORMATION: '/buyers/onboarding',
    SUPPLIERS: '/buyers/suppliers',
    ADD_SUPPLIERS: '/buyers/suppliers/add-suppliers',
    CSAA_FORM: '/buyers/csaa-form',
    ORDER_FORM: '/buyers/order-form',
    ORDER_FORM_NOTES: '/buyers/cart/notes',
  },

  COMMON: {
    HOME: '/',
    FORBIDDEN: '/403',
    NOT_FOUND: '/404',
    SIGNUP: '/signup',
    SIGNUP_SUCCESS: '/signup-success',
    TYPE_SELECTION: '/onboarding',
    PRODUCT: '/product',
    BRANDS: '/brands',
    FIRST_NATION_BRANDS: '/first-nation-brands',
    SEARCH: '/search',
    MARKETING_HUB: '/marketing-hub',
    COMBAT_SPORTS_SLUG: 'adidas-combat-sports',
    COMBAT_SPORTS_PRE_LOGIN: '/adidas-combat-sports',
  },

  ADMIN: {
    BASE_PATH: '/admin',
    DASHBOARD_BASE: '/admin/dashboard',
    DASHBOARD_BRANDS: '/admin/dashboard/brands',
    DASHBOARD_RETAILERS: '/admin/dashboard/retailers',
    DASHBOARD_ORDERS: '/admin/dashboard/orders',
    USER_SIGNUP: '/admin/user-signup',
    TAGS: '/admin/tags',
    COLLECTION: '/admin/collections',
    CUSTOMERS: '/admin/customers',
    INVITED_RETAILERS: '/admin/customers/invited-retailers',
  },

  NEXT_API: {
    AUTH: {
      LOGIN: '/api/auth/login',
      SIGNUP: '/api/auth/signup',
      LOGOUT: '/api/auth/logout',
      UPDATE_USER: '/api/auth/update-user',
      GET_TOKEN: '/api/auth/get-token',
    },
    HUBSPOT: {
      READ_CONTACT: '/api/hubspot/read-contact',
      CREATE_CONTACT: '/api/hubspot/create-contact',
      CREATE_TICKET: '/api/hubspot/create-ticket',
    },
    PRISMATIC: {
      GET_TOKEN: '/api/prismatic/get-token',
    },
    SHOPIFY: {
      AUTHENTICATE: '/api/shopify/authenticate',
    },
    KLAVIYO: {
      CREATE_EVENT: '/api/klaviyo/create-event',
    },
    POSTHOG: {
      GET_BRAND_PRODUCT_EVENTS: '/api/posthog/get-brand-product-events',
    },
  },
} as const;

export const BUYER_BRAND_PAGE_ROUTES = [
  '/brands/[brandSlug]',
  '/brands/[brandSlug]/search',
  '/brands/[brandSlug]/[category]',
  '/brands/[brandSlug]/[category]/[subcategory]',
  '/brands/[brandSlug]/[category]/[subcategory]/[subcategory2]',
];

export const SELLER_BRAND_PAGE_ROUTES = [
  '/sellers/brands/[brandId]',
  '/sellers/brands/[brandId]/search',
  '/sellers/brands/[brandId]/[category]',
  '/sellers/brands/[brandId]/[category]/[subcategory]',
  '/sellers/brands/[brandId]/[category]/[subcategory]/[subcategory2]',
];
