// https://mantine.dev/core/multi-select/

import { forwardRef } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
  MultiSelectStylesNames,
} from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { ClassArrayOrString } from '../../locals/types';
import { mergeMantineStyles } from '../../locals/utils';

type MultiSelectClassNamesType = Partial<Record<MultiSelectStylesNames, string>>;

const styles: ClassArrayOrString<MultiSelectClassNamesType> = {
  input:
    'disabled:cursor-not-allowed data-[error=true]:placeholder:text-andisor-navy-100 border-none text-base px-0 py-[10px] bg-transparent',
  inputField: 'placeholder:text-andisor-navy-400',
  section: 'pointer-events-none',
  wrapper: 'border-b',
  pillsList: 'mr-6 mobile:flex-col items-start justify-start',
  pill: 'w-min rounded text-sm',
};

export interface MultiSelectProps extends MantineMultiSelectProps {
  classNames?: MultiSelectClassNamesType;
}

const defaultComboboxProps = {
  withinPortal: false,
};

export const MultiSelect = forwardRef<HTMLInputElement, MultiSelectProps>(
  ({ classNames, comboboxProps: comboxPropsOverride, placeholder, value, ...restProps }, ref) => {
    const mergedStyles = mergeMantineStyles(styles, classNames);

    let comboboxProps = defaultComboboxProps;
    if (comboxPropsOverride) {
      comboboxProps = {
        ...defaultComboboxProps,
        ...comboxPropsOverride,
      };
    }

    return (
      <MantineMultiSelect
        ref={ref}
        classNames={mergedStyles}
        comboboxProps={comboboxProps}
        maxDropdownHeight={150}
        checkIconPosition="right"
        nothingFoundMessage="No results found"
        rightSection={<IconChevronDown className="ml-2" />}
        placeholder={!value?.length ? placeholder : ''}
        value={value}
        {...restProps}
      />
    );
  }
);
